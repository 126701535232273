import { Injectable } from "@angular/core";
import { RelatoriosData } from "../data/relatorios-table";

@Injectable()
export class RelatoriosTableService extends RelatoriosData {
  data = [
    {
      id: 1,
      name: "José Pedro da Silva",
      uf: "DF",
      city: "Brasília",
      location: "BSB",
      status: "1-Operando",
      date: "18/11/2019"
    },
    {
      id: 2,
      name: "Giovanni Albuquerque Peixoto",
      uf: "DF",
      city: "Brasília",
      location: "BSB",
      status: "1-Operando",
      date: "17/11/2019"
    },
    {
      id: 3,
      name: "Carlos Perereira",
      uf: "DF",
      city: "Brasília",
      location: "BSB",
      status: "1-Operando",
      date: "15/11/2019"
    },
    {
      id: 4,
      name: "João Pedro Gomes",
      uf: "DF",
      city: "Brasília",
      location: "BSB",
      status: "3-Manutenção",
      date: "16/11/2019"
    },
    {
      id: 5,
      name: "Patrick Soares",
      uf: "DF",
      city: "Brasília",
      location: "BSB",
      status: "1-Operando",
      date: "17/11/2019"
    },
    {
      id: 6,
      name: "José Filho de Abraão",
      uf: "SP",
      city: "São Paulo",
      location: "SAO",
      status: "2-Mau-funcionamento",
      date: "11/11/2019"
    },
    {
      id: 7,
      name: "Wagner Santos da Penha",
      uf: "SP",
      city: "Campinas",
      location: "VCP",
      status: "2-Mau-funcionamento",
      date: "12/11/2019"
    }
  ];

  getData() {
    return this.data;
  }
}
