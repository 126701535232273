import { Injectable } from "@angular/core";
import { SmartTableData } from "../data/smart-table";

@Injectable()
export class SmartTableService extends SmartTableData {
  data = [
    {
      dh: "21/10/19 19:21:00",
      ca: 1,
      desc: "Descrição",
      idufv: 1,
      pot: 1,
      idtic: 1,
      stattic: "Status Tic",
      stataten: "Status Aten",
      cid: "Cidade",
      est: "UF",
      end: "Endereço",
      latlng: [-22.955876, -43.166514],
      status: 0
    },
    {
      dh: "21/10/19 19:21:00",
      ca: 1,
      desc: "Descrição",
      idufv: 2,
      pot: 1,
      idtic: 1,
      stattic: "Status Tic",
      stataten: "Status Aten",
      cid: "Cidade",
      est: "UF",
      end: "Endereço",
      latlng: [-16.205060,-48.704990],
      status: 0
    },
    {
      dh: "21/10/19 19:21:00",
      ca: 1,
      desc: "Descrição",
      idufv: 3,
      pot: 1,
      idtic: 1,
      stattic: "Status Tic",
      stataten: "Status Aten",
      cid: "Cidade",
      est: "UF",
      end: "Endereço",
      latlng: [-29.451300,-51.306400],
      status: 2
    },
    {
      dh: "21/10/19 19:21:00",
      ca: 1,
      desc: "Descrição",
      idufv: 4,
      pot: 1,
      idtic: 1,
      stattic: "Status Tic",
      stataten: "Status Aten",
      cid: "Cidade",
      est: "UF",
      end: "Endereço",
      latlng: [-29.657950,-50.572720],
      status: 2
    },
    {
      dh: "21/10/19 19:21:00",
      ca: 1,
      desc: "Descrição",
      idufv: 5,
      pot: 1,
      idtic: 1,
      stattic: "Status Tic",
      stataten: "Status Aten",
      cid: "Cidade",
      est: "UF",
      end: "Endereço",
      latlng: [-28.726480,-52.844501],
      status: 1
    }
  ];
  
  getData() {
    return this.data;
  }
}



